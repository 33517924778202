.form-error {
  margin-top: 1.5rem;
}

.form-error > h3 {
  color: red;
  text-align: center;
  margin-bottom: 1rem;
  font-size: 1.625rem;
  line-height: 2rem;
  font-style: italic;
}

.form-error > ul {
  text-align: left;
  font-size: 1.125rem;
  line-height: 1.375rem;
  margin: 0 0 1.5rem 0;
}

.form-error > ul > li {
  margin-bottom: 1rem;
}

.form-error > ul > li:last-child {
  margin-bottom: 0;
}

.form-error > a {
  color: #66cc33;
  font-size: 1.125rem;
  line-height: 1.375rem;
  display: block;
  text-align: center;
  text-decoration: underline;
}

.form-error > a:hover {
  text-decoration: none;
}
