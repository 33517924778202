@keyframes spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.rotate {
  animation-duration: 1s;
  animation-name: spin;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
